#countdownpage-tip-wrapper {
  width:24rem;
  margin-left:auto;
  margin-right:auto;
  background-color:#F0F0F0;
  border-radius:2rem;
}

#tip-title {
  padding-top: 1rem;
}

#tip-content {
  padding-bottom: 1rem;
}

@media (max-width: 575.98px) { 
  #countdownpage-tip-wrapper {
    width:15rem;
    margin-left:auto;
    margin-right:auto;
    background-color:#F0F0F0;
    border-radius:2rem;
  }

  #tip-title {
    font-size: 1rem !important;
  }
  
  #tip-content {
    font-size: 0.75rem !important;
  }
}