#solve-check {
  position:absolute;
  left:2.2rem;
  top:-0.3rem;
}

#check-green {
  color:#60E68F;
  background-color:white;
  border-radius:50%;
}

#times-red {
  color:#FF6767;
  background-color:white;
  border-radius:50%;
}

#player-name-small {
  text-align:center;
  position:absolute;
  font-size: 0.76rem;
  left: 0rem;
  width:3rem;
}


.circle-small {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content:center;
}


.centerize-small {
  position:relative;
  text-align: center;
  margin: 0.9rem 1rem;
}

.name-small {
  color: white;
  font-size: 2rem;
}

.circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content:center;
}

.centerize {
  position:relative;
  text-align: center;
  margin: 1rem 1.5rem;
}

.name {
  color: white;
  font-size: 3rem;
}



@media (max-width: 767.98px) {
  .circle {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    display: flex;
    align-items:center;
    justify-content:center;
  }

  .name {
    color: white;
    font-size: 2.4rem;
  }

  .centerize {
    text-align: center;
    margin: 0.8rem 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items:center;
    justify-content:center;
  }

  .name {
    color: white;
    font-size: 1.6rem;
  }

  .centerize {
    text-align: center;
    margin: 0.2rem 0.5rem;
  }

  #player-name {
    font-size: 0.6rem;
  }
}

