#title-joinroompage {
  color: #707070;
  margin-top: 5rem !important;
  margin-bottom: 3rem !important;
}

.subtitle-joinroompage {
  color: #707070;
  margin-bottom: 1.5rem !important;
}

.input-joinroompage {
  margin-bottom: 2.5rem;
}
