#btn-selectpage-left {
  margin-right: 1.5rem;
}

#btn-selectpage-right {
  margin-left: 1.5rem;
}

#title-selectpage {
  color: #707070;
  margin-top: 5rem !important;
  margin-bottom: 6rem !important;
}

#text-selectpage {
  color: #707070;
  margin-bottom: 1.5rem !important;
}

#input-selectpage {
  margin-bottom: 2.5rem;
}

@media (max-width: 575.98px) {
  #btn-selectpage-left {
    margin-right: 0.9rem;
  }
  
  #btn-selectpage-right {
    margin-left: 0.9rem;
  }
}
