.solution-box {
  height:38%;
  width: 97%;
  border-radius: 2.5rem !important;
  border: 3.125rem solid #FFE0E0;
}

.ranking-box {
  height:28%;
  width: 97%;
  border-radius: 2.5rem !important;
  border: 2rem solid #FFDFB1;
  background-color: #FFDFB1;
}

.blue-dot {
  height:1rem;
  width:1rem;
  background-color:#76B0F7;
  border-radius: 50%;
  display:inline-block;
}

.red-dot {
  height:1rem;
  width:1rem;
  background-color:#F766D8;
  border-radius: 50%;
  display:inline-block;
}

.yellow-dot {
  height:1rem;
  width:1rem;
  background-color:#F7DC72;
  border-radius: 50%;
  display:inline-block;
}

.ranking-name {
  position: relative;
  top: 1.5rem; 
  left: 1rem;
}

.solution-player-name {
  position:relative;
  top:-0.2rem;
}

.swiper-container {
  background-color:grey;
  width:100%;
  height:100%;
}

.answer-correct-bg-color {
  background-color: #E0FFE2;
  border: 3.125rem solid #E0FFE2;
}

.answer-incorrect-bg-color {
  background-color: #FFE0E0;
  border: 3.125rem solid #FFE0E0;
}

@media (max-width: 1199.98px) {
  .solution-box {
    height:100%;
    width: 100%;
    border-radius: 0rem !important;
  }

  .solution-box-victory {
    height:100%;
    width: 100%;
    border-radius: 0rem !important;
    border-color:#E0FFE2;
  }

  .solution-box-lost {
    height:100%;
    width: 100%;
    border-radius: 0rem !important;
    border-color: #FFE0E0;
  }
  
  .ranking-box {
    height:100%;
    width: 100%;
    border-radius: 0rem !important;
    border: 2rem solid #FFDFB1;
    background-color: #FFDFB1;
  }

  .bottom-time-text {
    position:relative;
    bottom:1.7rem;
  }

  .col-content {
    /* This is very weird. When change the padding
    to 0px, the content will just DISAPPEAR! */
    padding:0.01px !important;
    height:90% !important;
  }

  .table-answer {
    margin: 0px !important;
  }

  h3 {
    font-size:0.9rem !important;
  }

  h2 {
    font-size:1.1rem !important;
  }

  h6 {
    font-size:0.8rem !important;
  }

  .ranking-name {
    position: relative;
    top: 1rem; 
    left: 1rem;

  }

}