#rightside-hostpage {
  width:60%;
  margin-left:auto;
  margin-right:auto;
}

#room-number-text {
  font-size: 3rem !important;
}

@media (max-width: 991.98px) {
  #rightside-hostpage {
    width:90%;
    margin-left:auto;
    margin-right:auto;
  }
}
@media (max-width: 767.98px) {
  #rightside-hostpage {
    width:90%;
    margin-left:auto;
    margin-right:auto;
  }
}

@media (max-width: 575.98px) {
  #rightside-hostpage {
    width:100%;
    margin-left:auto;
    margin-right:auto;
  }

  #wait-text {
    font-size: 0.8rem!important;
  }
}
