.switch {
  position: relative;
  display: inline-block;
  width: 3.75rem;
  height: 2.125rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .15s;
  transition: .15s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.625rem;
  width: 1.625rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: white;
  -webkit-transition: .15s;
  transition: .15s;
}

input:checked + .slider {
  background-color: #76E092;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.625rem);
  -ms-transform: translateX(1.625rem);
  transform: translateX(1.625rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2.125rem;
}
.slider.round:before {
  border-radius: 50%;
}