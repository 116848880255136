.btn-blue {
  width: 7.5rem !important; /* 120px by default */
  height: 3.125rem !important; /* 50px by default */
  border: 0.125rem solid #4194F8 !important;
  border-radius: 1.5625rem !important;
  background-color: #4194F8 !important;
  font-size: 1rem !important; /* 19px by default */
  color: #FFFFFF !important;
}

.btn-blue:hover {
  background-color: #FFFFFF !important;
  color: #4194F8 !important;
}

.btn-blue:active {
  background-color: #F5F5F5 !important;
  color: #4194F8 !important;
}

.btn-blue:disabled {
  background-color: #4194F8 !important;
  opacity: 75% !important;
}

.btn-white {
  width: 7.5rem !important; /* 120px by default */
  height: 3.125rem !important; /* 50px by default */
  border: 0.125rem solid #707070 !important;
  border-radius: 1.5625rem !important;
  background-color: #FFFFFF !important;
  font-size: 1rem !important; /* 19px by default */
  color: #707070 !important;
}

.btn-white:hover {
  background-color: #707070 !important;
  color: #ffffff !important;
}

.btn-white:active {
  background-color: #505050 !important;
  color: #ffffff !important;
}

.btn-white:disabled {
  background-color: #FFFFFF !important;
  opacity: 75% !important;
}
