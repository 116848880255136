.title-homepage {
  font-size: 9.375rem; /* 150px by default */
  color: #707070;
}

.link-homepage {
  /* opacity: 75%; */
  font-size: 1rem; /* 16px by default */
  transition-duration: 0.15s;
}

.flex-direction {
  flex-direction: row;
}

.btn-homepage {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#change-lang {
  color: #707070;
  opacity: 75%;
  margin-left: 2rem;
}

@media (max-width: 575.98px) {
  .flex-direction {
    flex-direction: column;
  }
}
