.btn-outline {
  width: 15.625rem; /* 250px by default */
  height: 3.125rem; /* 50px by default */
  border: 0.125rem solid #707070 !important;
  border-radius: 1.5625rem !important;
  background-color: #ffffff !important;
  font-size: 1.1785rem !important; /* 19px by default */
  color: #707070;
}

.btn-outline:hover {
  background-color: #707070 !important;
  color: #ffffff !important;
  border-color: #707070 !important;
}

.btn-outline:active {
  background-color: #505050 !important;
  color: #ffffff !important;
}
