:root {
  --scroll-gradient-height: 5rem;
}

.solution {

}

.solution-overflow {
  height: 30rem;
  overflow: auto;
  position: relative;
  /* background-color: white;
  background-image: linear-gradient(to bottom, white, transparent); */
}

.solution-overflow::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.solution-overflow {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.result-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4);

}

#delete-cross {
  text-decoration: none;
}

.result-modal-body {
  background-color: #fefefe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  height: 80%;
  border-radius: 2rem;
  text-align:center;
  line-height: 75%;
}

.result-modal-body-with-fadeout {
  background-color: #fefefe;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  height: 80%;
  border-radius: 2rem;
  text-align:center;
  line-height: 75%;
}

#solution-top-text-with-fadeout::after {
  content:"";
  height: var(--scroll-gradient-height);
  width:100%;
  display:block;
  position:relative;
  top:2.5rem;
  z-index:1;
  background: -webkit-linear-gradient(
      red,
      black
  ); 
  background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
  );
}

#solution-top-text::after {
  content:"";
  height: var(--scroll-gradient-height);
  width:100%;
  display:block;
  position:relative;
  top:2.5rem;
}


.result-modal-body-with-fadeout::before {
  
} 

.result-modal-body-with-fadeout::after {
  content:"";
  height: var(--scroll-gradient-height);
  width:100%;
  display:block;
  position:relative;
  bottom:5rem;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  ); 
  background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
  ); 
}

.result-modal-delete {
  color: #aaa;
  float: left;
  font-size: 2rem;
  font-weight: bold;
  position:relative;
  left:0.2rem;
}

.result-modal-delete:hover,
.result-modal-delete:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#solvePage-mainContent {
  margin-top: 20rem;
}

@media (max-width: 1199.98px) {
  #solvePage-mainContent {
    margin-top: 0rem;
  }
}